import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

// Core React 
import StripeContainer from './../../containers/payments/stripe-container';
// import CbInput from "./../admin/common/forms/cb-input";
import TextInput from "./../admin/common/forms/text-input";

import LwLogo from "./../../assets/img/lw/livingwell-logo.svg";

import {
    useGoCardlessDropin,
  } from '@gocardless/react-dropin';
import Tiles from "../common/tiles";
import MenusContainer from "../../containers/pages/menus-container";
import WordingContainer from "../../containers/common/wording/wording-container";
import CbInput from "../admin/common/forms/cb-input";

// Display a button that opens the Dropin on click, starting a checkout
// flow for the specified Billing Request Flow.
const DropinButton = (options) => {
    const { open } = useGoCardlessDropin({ ...options });
  
    return (
        <button type="button" onClick={() => open()} className="pay-btn">
            Set up direct debit
        </button>
    );
  };

function Donations(props) {

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = (e) => {
        e.preventDefault();
        setMenuOpen(!menuOpen);
    }

    return (
        <div className="donate">

            <header className={ menuOpen ? "mobile-open mobile-header" : "mobile-header" }>
                <div className="logo left sml">
                    <a href="/">
                        <img src={LwLogo} alt="Living Well logo" />
                    </a>
                </div>
                <nav className={menuOpen ? "main centred open" : "main centred"}>
                    <button className="mobile-menu menu-toggle" onClick={(e) => toggleMenu(e)}>Menu</button>
                    <MenusContainer menuSlug="main" setMenuOpen={setMenuOpen} />
                </nav>
            </header>

            <div className="half title red">
                <Link to="/"><img src={ LwLogo } alt="Living Well Bromley Logo" /></Link>
                <h1>Donate</h1>
                <h4><WordingContainer wordName="donate-intro" default="We support <span class='major'>250 adults</span> &amp; <span class='major'>100 children</span> each week with <span class='major'>£3800 worth of food parcels</span>." /></h4>

                <Tiles no={4} size="big" direction="row" />
            </div>
            
            <div className="half form">

                <div className="form">
                    <ul className="tab-btns">
                        <li><button className={ props.recurrence === "regular" ? "current" : "" } onClick={ (e) => props.updateDonationRecurrence(e, "regular") }>Regular Donation</button></li>
                        <li><button className={props.recurrence === "one-off" ? "current" : ""} onClick={(e) => props.updateDonationRecurrence(e, "one-off") }>One-off Donation</button></li>
                    </ul>

                    {!props.provider ? ( 

                        <Fragment>
                            <ul className="amount-options">
                                <li className={ props.amount === 10 ? "current" : "" }>
                                    <button onClick={(e) => props.setAmount(10)} className={props.recurrence === "regular" ? "monthly" : ""}>
                                        <span className="amount">&pound;10{props.recurrence === "regular" && <span>per month</span>}</span>
                                        <span className="desc"><WordingContainer wordName="donate10" default="Pays for a food parcel for one adult" /></span>
                                    </button>
                                </li>
                                <li className={props.amount === 20 ? "current" : ""}>
                                    <button onClick={(e) => props.setAmount(20)} className={props.recurrence === "regular" ? "monthly" : ""}>
                                        <span className="amount">&pound;20{ props.recurrence === "regular" && <span>per month</span> }</span>
                                        <span className="desc"><WordingContainer wordName="donate25" default="Pays for a food parcel for two children" /></span>
                                    </button>
                                </li>
                                <li className={props.amount === 50 ? "current" : ""}>
                                    <button onClick={(e) => props.setAmount(50)} className={props.recurrence === "regular" ? "monthly" : ""}>
                                        <span className="amount">&pound;50{props.recurrence === "regular" && <span>per month</span>}</span>
                                        <span className="desc"><WordingContainer wordName="donate50" default="Will give 25 people a home-cooked meal" /></span>
                                    </button>
                                </li>
                                <li className={props.amount !== 10 && props.amount !== 25 && props.amount !== 50 ? "current" : ""}>
                                    <span className="input amount">
                                        <label>Other amount</label>
                                        <input type="text" onChange={(e) => props.setAmount(e.target.value)} />
                                        {props.recurrence === "regular" && 
                                            <span className="addn-desc">per month</span>
                                        }
                                    </span>
                                </li>
                            </ul>


                            { props.recurrence === "one-off" && 
                                <Fragment>
                                    <button className="pay-btn" onClick={() =>  props.setProvider("stripe")}>Pay by card</button>
                                    <button className="pay-btn" onClick={() => props.handleGoCardlessPaymentType('one-off')}>Pay by direct debit</button>
                                </Fragment>
                            }
                            {props.recurrence === "regular" && 
                                <button className="pay-btn" onClick={() =>  props.handleGoCardlessPaymentType('direct-debit')}>Setup Payment</button>
                            }
                        </Fragment>

                    ) : (
                
                        <Fragment>

                            <div className="confirm-pane">
                                <h3>&pound;{props.amount}</h3>
                                <p>Thanks for agreeing to donate to Living Well. Please complete the payment below.</p>
                            </div>

                            { props.provider  === "stripe" && props.amount && 
                                <Fragment>
                                    <TextInput label="First name" fieldName="firstName" handleChange={props.updateUserDetail } />
                                    <TextInput label="Last name" fieldName="lastName" handleChange={props.updateUserDetail} />
                                    <TextInput label="Email" fieldName="email" handleChange={props.updateUserDetail} />
                                    {/*<CbInput label="I am eligible to claim Gift Aid" fieldName="giftAid" updateField={props.updateUserDetail} />*/}
                                </Fragment>
                            }

                            { props.provider === "stripe" && 
                                <StripeContainer { ...props } />
                            }

                            { props.provider  === "gocardless" && props.amount && props.goCardlessFlowID   !== null &&
                                <Fragment>
                                    {/* <TextInput label="First name" fieldName="firstName" updateField={ props.setUserDetail } />
                                    <TextInput label="Last name" fieldName="lastName" updateField={props.setUserDetail} />
                                    <TextInput label="Email" fieldName="email" updateField={props.setUserDetail} /> */}
                                    {/* <p>Select a Payment Type</p>
                                    <input id="one-off"  name="provider" type="radio" checked={props.type === 'one-off'} onChange={() => props.setType('one-off')} /><label htmlFor="one-off">One Off</label>
                                    <input id="direct-debit" name="provider" type="radio" checked={props.type === 'direct-debit'} onChange={() => props.setType('direct-debit')} /><label htmlFor="direct-debit">Direct Debit</label> */}
                                    <CbInput label="I confirm that I am a UK taxpayer and am eligible to claim Gift Aid on this donation" fieldName="giftAid" updateField={props.updateUserDetail} />
                                    <DropinButton billingRequestFlowID={props.goCardlessFlowID} key={'type-' + props.type + '-' + props.giftAid} environment={process.env.REACT_APP_GC_ENV } onSuccess={props.handleDirectDebitMandateSuccess} />
                                    
                                </Fragment>
                            }
                        </Fragment>

                    )}
                </div>
            </div>
        </div>
    );
}

export default Donations;
